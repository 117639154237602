// Once we have a better understanding of the analytics tools we want to use, we can rename
// these functions and consolidate this file so we have a single event layer for analytics.

export const trackEvent = (event, properties) => {
  window?.analytics && window.analytics.track(event, properties);
  window?.posthog && window.posthog.capture(event, properties);
};

export const identify = (properties) => {
  window?.analytics && window.analytics.identify(properties);

  if (window?.posthog) {
    const phDistinctId = window.posthog?.get_distinct_id?.();
    phDistinctId && window.posthog.identify(phDistinctId, properties);
  }
};

export const trackBingConversion = (event, properties = {}) => {
  window.uetq = window.uetq || [];
  window.uetq.push("event", event, properties);
};
