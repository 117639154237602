import React, { useRef } from "react";
import usePortal from "react-useportal";
import classNames from "classnames";
import * as c from "./useModal.module.scss";
import { IconClose } from "../components/icons/iconClose";
import Helmet from "react-helmet";

const NULL_EVENT = { currentTarget: { contains: () => false } };

export const useModal = ({ onOpen, onClose, backgroundColor, isReferralModal, ...config } = {}) => {
  const modalRef = useRef();

  const backgroundColors = ["navy", "grey"];
  const backgroundColorClass = backgroundColors.includes(backgroundColor) ? c[backgroundColor] : c.navy;

  const { isOpen, togglePortal, openPortal, closePortal, Portal } = usePortal({
    onOpen(event) {
      const { portal } = event;
      portal.current.classList.add(c.overlay, backgroundColorClass);
      if (onOpen) onOpen(event);
    },
    onClose(event) {
      const { portal } = event;
      portal.current.classList.remove(c.overlay, backgroundColorClass);
      if (onClose) onClose(event);
    },
    onPortalClick({ target }) {
      const clickingOutsideModal = modalRef && modalRef.current && !modalRef.current.contains(target);
      if (clickingOutsideModal) closePortal();
    },
    ...config,
  });

  const Modal = ({ children, ...props }) => (
    <>
      <Helmet htmlAttributes={{ class: classNames({ [c.disableDocumentScroll]: isOpen }) }} />
      <Portal>
        <div
          ref={modalRef}
          className={classNames(
            c.modal,
            "useModalContentWrap",
            isOpen && "useModalContentOpen",
            isReferralModal && c.referralModal
          )}
          {...props}
        >
          <button className={c.closeButton} onClick={closePortal}>
            <IconClose />
          </button>
          {children}
        </div>
      </Portal>
    </>
  );

  /*
    We default to passing NULL_EVENT for togglePortal and openPortal since
    otherwise an error will get thrown if we don't have any event to pass.

    As far as I can tell, the event isn't strictly needed so there doesn't
    seem to be any good reason for this error to be thrown in the first place.
    We should probably still pass an event when we have one.

    By defaulting to a NULL_EVENT we make it possible to call these functions
    programatically, e.g. inside a useEffect or as a side effect.

    Context: https://github.com/alex-cory/react-useportal/issues/36
  */
  return Object.assign([openPortal, closePortal, isOpen, Modal, togglePortal], {
    Modal,
    toggleModal: (event = NULL_EVENT) => togglePortal(event),
    openModal: (event = NULL_EVENT) => openPortal(event),
    closeModal: closePortal,
    isModalOpen: isOpen,
  });
};

export default useModal;
