import React from "react";
import { ReferralModal } from "./referralModal";

const ReferralModalPartner = () => {
  const { Title, Text, CloseButton } = ReferralModal;
  return (
    <ReferralModal referralType="partner">
      <Title>Want to give us a try?</Title>
      <Text>
        <strong>Get 2 months of Mangomint on us,</strong> including onboarding support and (free!) data transfer. Book a
        demo to speak with our team.
      </Text>
      <CloseButton>Sounds good!</CloseButton>
    </ReferralModal>
  );
};

export { ReferralModalPartner };
